<template>
    <div id="part-category-manufacturers" class="container-left">
        <h2 class="mt-0">{{ $t("PartCategory.ECIAManufacturers") }} ({{ manufacturers?.length }})</h2>
        <GridItems :items="sortedManufacturers" item-key="ManufacturerId">
            <template #item="{ item: mfr }">
                <NuxtLink v-if="mfr.LogoUrl" class="flex-1 hover:no-underline" :to="categoryPath(mfr)">
                    <img class="w-28 inline mr-2" :src="cdnUrl + mfr.LogoUrl" :alt="mfr.Name" />
                    <span v-if="mfr.LogoText" class="font-bold align-top text-lg inline-block">{{ mfr.LogoText }}</span>
                </NuxtLink>
                <h3 v-else class="flex-1">
                    <NuxtLink :to="categoryPath(mfr)">{{ mfr.Name }}</NuxtLink>
                </h3>
                <div>{{ $t("Global.PartsInStock", [mfr.PartsInStock.toLocaleString()]) }}</div>
                <div>{{ $t("PartCategory.TotalParts", [mfr.TotalParts.toLocaleString()]) }}</div>
            </template>
        </GridItems>
    </div>
</template>
<script setup lang="ts">
const localePath = useLangPath();
const config = useRuntimeConfig();
const category = useStateCategory();

const { data: manufacturers } = await useFetchApi<Manufacturer[]>(`api/category/${category.value?.Id}/manufacturers`);
const cdnUrl = config.public.cdnUrl;

function categoryPath(manufacturer: Manufacturer) {
    return localePath(`/part-category/${category.value?.Path}/${manufacturer.VirtualPath}`);
}

const sortedManufacturers = computed(() => {
    if (!manufacturers.value) return [];
    return [...manufacturers.value].sort((a, b) => b.PartsInStock - a.PartsInStock);
});
</script>

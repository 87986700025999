<template>
    <section class="flex flex-col gap-8 text-base">
        <CategoryHeader />
        <p class="container-left">{{ categoryDescription }}</p>
        <template v-if="hasChildren">
            <h2 class="container-left my-0">{{ $t("PartCategory.Subcategories") }} ({{ children.length }})</h2>
            <ul class="container-left lg:columns-4 list-disc">
                <li v-for="child in children" :key="child.Path" class="ml-4 mb-4 pl-4 break-inside-avoid">
                    <NuxtLink :to="localePath('/part-category/' + child.Path)">
                        {{ child.Name }}
                    </NuxtLink>
                </li>
            </ul>
            <CategoryLeadTime v-if="!isManufacturerPage" />
        </template>
        <template v-else>
            <CategoryManufacturers v-if="!isManufacturerPage" />
            <CategoryLeadTime v-if="!isManufacturerPage" />
        </template>
        <template v-if="showParametricSearch">
            <h2 class="container-left my-0">{{ $t("Global.Parts") }}</h2>
            <CategoryFilters />
            <CategoryParts />
        </template>
    </section>
</template>
<script setup lang="ts">
const globalConfig = useStateGlobalConfig();
const { t } = useI18n();
const route = useRoute();
const localePath = useLangPath();
const category = useStateCategory();
const filterOptions = useStateFilterOptions();

const { path } = route.params;
const pathParts = Array.isArray(path) ? path : [path];

await useFetchLocaleApi<Category>(`api/categories/${pathParts.join("/")}`, {
    onResponse({ response }) {
        category.value = response._data;
    },
});

useSeoMeta({
    title: category.value?.Name,
    ogTitle: category.value?.Name,
    description: t("PartCategory.MetaDescription", [category.value?.Name]),
    ogDescription: t("PartCategory.MetaDescription", [category.value?.Name]),
});

const children = computed<Category[]>(() => {
    return category.value?.Children ?? [];
});

const hasChildren = computed(() => {
    return children.value.length > 0;
});

const isManufacturerPage = computed(() => {
    return !!category.value?.Manufacturer;
});

const categoryDescription = computed(() => {
    if (!category.value) return "";
    if (category.value.Description) return category.value.Description;
    if (hasChildren.value) {
        return t("PartCategory.DefaultCategoryLeafDescription", [category.value.Name]);
    }
    const [firstChild] = category.value.Children ?? [category.value];
    return t("PartCategory.DefaultCategoryDescription", [category.value.Name, firstChild?.Name]);
});

// load filters if this is a leaf node category
const showParametricSearch = computed(() => {
    return globalConfig.value.Features.ALL_CATEGORIES_PARAMETRIC_SEARCH
        ? category.value?.IsParametricSearchEnabled
        : !hasChildren.value;
});
useFetchLocaleApi<FilterOption[]>(`api/category/${category.value?.Id}/filters`, {
    onResponse({ response }) {
        filterOptions.value = response._data ?? [];
    },
});
</script>

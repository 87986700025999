<template>
    <PageHeader wide>
        <CategoryLogo v-if="rootCategory" :category="rootCategory" size="md" />
        <div>
            <CategoryBreadcrumbs :categories="category?.Parents" />
            <h1 v-if="category?.Manufacturer" class="flex gap-2 my-2">
                <NuxtLink :to="localePath('/part-category/' + category.Path)">
                    {{ category.Name }}
                </NuxtLink>
                <i class="fass fa-chevron-right"></i>
                <span>{{ category.Manufacturer.Name }}</span>
            </h1>
            <h1 v-else class="my-2">{{ category?.Name }}</h1>
        </div>
    </PageHeader>
</template>
<script setup lang="ts">
const localePath = useLangPath();
const category = useStateCategory();

const rootCategory = computed<Category | null>(() => {
    if (Array.isArray(category.value?.Parents) && category.value.Parents.length > 0) return category.value.Parents[0];
    return category.value;
});
</script>

<template>
    <div v-if="hasLeadTimes" id="part-category-lead-time" class="container-left">
        <h2 class="mt-0">{{ $t("BOMTool.FactoryLeadTime") }}</h2>
        <table class="mb-4 w-full max-w-2xl">
            <thead>
                <tr class="text-left">
                    <th class="p-2">{{ $t("PartCategory.Category") }}</th>
                    <th class="p-2">{{ $t("AdditionalFeatures.AverageFactoryLeadTime") }} *</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in leadTimesList" :key="row.CategoryId" class="border-t">
                    <td class="p-2">{{ row.CategoryName }}</td>
                    <td class="p-2">{{ $t("Search.FactoryLeadTimeWeeks", [row.AverageWeeks]) }}</td>
                </tr>
            </tbody>
        </table>
        <p class="max-w-2xl">* {{ $t("AdditionalFeatures.AverageFactoryLeadTimeNote") }}</p>
    </div>
</template>
<script setup lang="ts">
const category = useStateCategory();

type LeadTimeItem = {
    CategoryId: number;
    CategoryName: string;
    Average: number;
};

const { data: leadTimes } = await useFetchLocaleApi<LeadTimeItem[]>(`api/category/${category.value?.Id}/lead-times`, {
    deep: false,
});

const leadTimesList = computed(() => {
    if (!leadTimes.value) return [];
    return leadTimes.value.map((item) => ({ ...item, AverageWeeks: (item.Average / 7).toFixed(1) }));
});

const hasLeadTimes = computed(() => Array.isArray(leadTimes.value) && leadTimes.value.length > 0);
</script>
